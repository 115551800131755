import React, { useState, useRef, useEffect } from 'react';
import { X, ChevronRight, ChevronLeft } from 'lucide-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import zhTWLocale from 'date-fns/locale/zh-TW';
import { addMinutes, setHours, setMinutes, startOfHour, max } from 'date-fns';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Autocomplete } from '@react-google-maps/api';

registerLocale('zh-TW', zhTWLocale);
setDefaultLocale('zh-TW');

const PREPARATION_TIME = 15; // 準備時間（分鐘）

//const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
console.log('1 REACT_APP_GOOGLE_MAPS_API_KEY=', process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
console.log('環境變數:', process.env);
const GOOGLE_MAPS_API_KEY ='AIzaSyBOCq0j_2qYPs2zp5chhuP7bkyTkdHgrrA';
const RESTAURANT_ADDRESS = '338桃園市蘆竹區中正路142號';

const CheckoutModal = ({ isOpen, onClose, cart, totalPrice, tableNumber, dishOptions, dishOptionItems, onCheckout, orderType }) => {
  const [step, setStep] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [orderDetails, setOrderDetails] = useState({
    deliveryTime: getDefaultDeliveryTime(),
    address: '',
    name: '',
    phone: '',
    duration: '',
    priority: 3,
  });
  const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState(null);
  const [center, setCenter] = useState({ lat: 25.0330, lng: 121.5654 });
  const [marker, setMarker] = useState(null);
  const [calculating, setCalculating] = useState(false);
  const [travelTime, setTravelTime] = useState(null);
  const autocompleteRef = useRef(null);

  // 獲取默認送達時間
  function getDefaultDeliveryTime() {
    const now = new Date();
    const thirtyMinutesLater = addMinutes(now, PREPARATION_TIME);
    return roundToNearestFifteen(thirtyMinutesLater);
  }

  function roundToNearestFifteen(date) {
    const minutes = date.getMinutes();
    const roundedMinutes = Math.ceil(minutes / 15) * 15;
    return setMinutes(date, roundedMinutes % 60);
  }


  const handlePayment = async () => {
    try {
      await onCheckout(paymentMethod, orderDetails);
      setStep(1);
      onClose();
    } catch (error) {
      console.error('結帳時發生錯誤：', error);
      alert('結帳過程中發生錯誤，請稍後再試。');
    }
  };

  const handleClose = () => {
    setStep(1);
    onClose();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails(prev => ({ ...prev, [name]: value }));
  };

  // 處理送達時間變更
  const handleDeliveryTimeChange = (date) => {
    setOrderDetails(prev => ({ ...prev, deliveryTime: date }));
  };


  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setMarker({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setOrderDetails(prev => ({ ...prev, address: place.formatted_address }));
    }
  };

  const calculateDeliveryTime = () => {
    if (!orderDetails.address) {
      alert('請先輸入或選擇外送地址');
      return;
    }

    setCalculating(true);
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [RESTAURANT_ADDRESS],
        destinations: [orderDetails.address],
        travelMode: 'DRIVING',
        unitSystem: window.google.maps.UnitSystem.METRIC,
      },
      (response, status) => {
        if (status === 'OK' && response.rows[0].elements[0].status === 'OK') {
          const duration = Math.ceil(response.rows[0].elements[0].duration.value / 60);
          setTravelTime(duration);
          const totalDuration = duration + PREPARATION_TIME;
          const estimatedTime = addMinutes(new Date(), totalDuration);
          setEstimatedDeliveryTime(estimatedTime);
          setOrderDetails(prev => ({ 
            ...prev, 
            duration: totalDuration,
            deliveryTime: roundToNearestFifteen(max([estimatedTime, getDefaultDeliveryTime()]))
          }));
        } else {
          console.error('Distance Matrix failed due to: ' + status);
          alert('無法計算送貨時間，請檢查地址是否正確');
        }
        setCalculating(false);
      }
    );
  };

 
  // 過濾可選時間
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    if (estimatedDeliveryTime) {
      return selectedDate >= estimatedDeliveryTime;
    }

    return addMinutes(currentDate, PREPARATION_TIME) < selectedDate;
  };



  const renderProgressBar = () => (
    <div className="flex justify-between mb-6">
      {['確認餐點', '訂單詳情', '選擇支付方式'].map((text, index) => (
        <div key={index} className="flex flex-col items-center">
          <div className={`w-8 h-8 rounded-full flex items-center justify-center ${step > index ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-600'}`}>
            {index + 1}
          </div>
          <span className="text-xs mt-1">{text}</span>
        </div>
      ))}
    </div>
  );

  const renderStep1 = () => (
    <div>
      <h2 className="text-xl font-bold mb-4">確認餐點</h2>
      <div className="max-h-60 overflow-y-auto mb-4">
        {cart.map((item, index) => (
          <div key={index} className="mb-2 pb-2 border-b">
            <p className="font-bold">{item.name} x {item.quantity}</p>
            <p className="text-sm text-gray-600">${item.price * item.quantity}</p>
            {Object.entries(item.options).map(([optionId, selectedItems]) => {
              const option = dishOptions.find(o => o.id === parseInt(optionId));
              if (Array.isArray(selectedItems)) {
                return selectedItems.map(itemId => {
                  const optionItem = dishOptionItems.find(oi => oi.id === itemId);
                  return (
                    <p key={itemId} className="text-sm text-gray-500">
                      {option?.name}: {optionItem?.name} (+${optionItem?.price})
                    </p>
                  );
                });
              } else {
                const optionItem = dishOptionItems.find(oi => oi.id === selectedItems);
                return (
                  <p key={optionId} className="text-sm text-gray-500">
                    {option?.name}: {optionItem?.name} (+${optionItem?.price})
                  </p>
                );
              }
            })}
            {item.note && (
              <p className="text-sm text-gray-500">備註: {item.note}</p>
            )}
          </div>
        ))}
      </div>
      <p className="font-bold text-lg mb-4">總計: ${totalPrice}</p>
      <button
        onClick={() => setStep(2)}
        className="w-full bg-green-500 text-white px-4 py-2 rounded flex items-center justify-center"
      >
        下一步 <ChevronRight size={20} className="ml-2" />
      </button>
    </div>
  );

  const renderStep2 = () => (
      <div>
        <h2 className="text-xl font-bold mb-4">訂單詳情</h2>
        <div className="space-y-4 mb-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">用餐方式</label>
            <p className="mt-1 text-lg font-semibold">
              {orderType === 'dineIn' ? '內用' : orderType === 'takeout' ? '外帶自取' : '外送'}
            </p>
          </div>
          {orderType === 'dineIn' && (
            <div>
              <label className="block text-sm font-medium text-gray-700">桌號</label>
              <p className="mt-1 text-lg font-semibold">{tableNumber}</p>
            </div>
          )}
          {orderType === 'delivery' && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700">送餐地址</label>
                <Autocomplete
                  onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                  onPlaceChanged={handlePlaceSelect}
                >
                  <input
                    type="text"
                    name="address"
                    value={orderDetails.address}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    placeholder="輸入或選擇外送地址"
                  />
                </Autocomplete>
              </div>
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: '200px' }}
                center={center}
                zoom={12}
                options={{ disableDefaultUI: true, zoomControl: false, gestureHandling: 'none' }}
              >
                {marker && <Marker position={marker} />}
              </GoogleMap>
              <button
                onClick={calculateDeliveryTime}
                className="w-full bg-blue-500 text-white px-4 py-2 rounded"
                disabled={calculating}
              >
                {calculating ? '計算中...' : '計算送餐時間'}
              </button>
              {estimatedDeliveryTime && (
            <div className="mt-4 p-4 bg-gray-100 rounded-lg">
            <h3 className="text-lg font-semibold mb-2">預估送餐時間詳情</h3>
            <p className="text-sm mb-1">
              <span className="font-medium">準備時間：</span>{PREPARATION_TIME} 分鐘
            </p>
            <p className="text-sm mb-1">
              <span className="font-medium">交通所需時間：</span>{travelTime} 分鐘
            </p>
            <p className="text-sm mb-3">
              <span className="font-medium">總計時間：</span>{orderDetails.duration} 分鐘
            </p>
            <p className="text-base font-semibold">
              預計送達時間：{estimatedDeliveryTime.toLocaleTimeString('zh-TW', { hour: '2-digit', minute: '2-digit' })}
            </p>
                </div>
              )}
            </>
          )}
          {orderType !== 'dineIn' && (
            <>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">
                  {orderType === 'takeout' ? '希望取餐時間' : '希望送達時間'}
                </label>
                <DatePicker
                  selected={orderDetails.deliveryTime}
                  onChange={handleDeliveryTimeChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="時間"
                  dateFormat="yyyy年MM月dd日 HH:mm"
                  minDate={new Date()}
                  minTime={setHours(setMinutes(new Date(), 0), 0)}
                  maxTime={setHours(setMinutes(new Date(), 45), 23)}
                  filterTime={filterPassedTime}
                  placeholderText="選擇日期和時間"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">姓名</label>
                <input
                  type="text"
                  name="name"
                  value={orderDetails.name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">電話</label>
                <input
                  type="tel"
                  name="phone"
                  value={orderDetails.phone}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
            </>
          )}
          {orderType === 'delivery' && (
            <div>
              <label className="block text-sm font-medium text-gray-700">優先級</label>
              <select
                name="priority"
                value={orderDetails.priority}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              >
                <option value={1}>緊急</option>
                <option value={2}>優先</option>
                <option value={3}>一般</option>
              </select>
            </div>
          )}
        </div>
        <div className="flex justify-between">
          <button
            onClick={() => setStep(1)}
            className="bg-gray-300 text-black px-4 py-2 rounded flex items-center"
          >
            <ChevronLeft size={20} className="mr-2" /> 上一步
          </button>
          <button
            onClick={() => setStep(3)}
            className="bg-green-500 text-white px-4 py-2 rounded flex items-center"
          >
            下一步 <ChevronRight size={20} className="ml-2" />
          </button>
        </div>
      </div>
  );

  const renderStep3 = () => (
    <div>
      <h2 className="text-xl font-bold mb-4">選擇支付方式</h2>
      <div className="space-y-2 mb-4">
        {['信用卡', 'Line Pay', '現金'].map((method) => (
          <button
            key={method}
            onClick={() => setPaymentMethod(method)}
            className={`w-full p-2 border rounded ${paymentMethod === method ? 'bg-green-200 text-black' : 'bg-white'}`}
          >
            {method}
          </button>
        ))}
      </div>
      <div className="flex justify-between">
        <button
          onClick={() => setStep(2)}
          className="bg-gray-300 text-black px-4 py-2 rounded flex items-center"
        >
          <ChevronLeft size={20} className="mr-2" /> 上一步
        </button>
        <button
          onClick={handlePayment}
          className="bg-green-500 text-white px-4 py-2 rounded flex items-center"
          disabled={!paymentMethod}
        >
          確認付款
        </button>
      </div>
    </div>
  );

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">
            結帳
          </h2>
          <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        {renderProgressBar()}
        {step === 1 ? renderStep1() : step === 2 ? renderStep2() : renderStep3()}
      </div>
    </div>
  );
};

export default CheckoutModal;

