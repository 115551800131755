import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { Loader2, ChevronLeft, ChevronRight, X, ShoppingCart } from 'lucide-react';
import CheckoutModal from './CheckoutModal';
import liff from '@line/liff';
import moment from 'moment';

const PCLOUD_IMAGE_PATH = process.env.REACT_APP_PCLOUD_IMAGE_PATH;
const RESTAURANT_ADDRESS = '338桃園市蘆竹區中正路142號'; 

const GET_RESTAURANT_DATA = gql`
  query GetRestaurantData($restaurantId: Int!) {
    restaurants_by_pk(id: $restaurantId) {
      id
      name
    }
    categories(where: {restaurant_id: {_eq: $restaurantId}}) {
      id
      name
    }
    dishes(where: {restaurant_id: {_eq: $restaurantId}, is_available: {_eq: true}}) {
      id
      name
      description
      price
      image_url
      category_id
    }
    dish_options {
      id
      name
      type
      dish_id
    }
    dish_option_items {
      id
      name
      price
      dish_option_id
    }
    tables(where: {restaurant_id: {_eq: $restaurantId}}) {
      id
      table_number
      status
    }
  }
`;

const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($input: orders_insert_input!) {
    insert_orders_one(object: $input) {
      id
      table_id
      total_amount
      created_at
      updated_at
      status
      pay_method
      priority
      customer_info
      
    }
  }
`;

const CREATE_ORDER_ITEMS_MUTATION = gql`
  mutation CreateOrderItems($objects: [order_items_insert_input!]!) {
    insert_order_items(objects: $objects) {
      affected_rows
      returning {
        id
        order_id
        dish_id
        quantity
        unit_price
        subtotal
        dish_items_text
      }
    }
  }
`;

const DELETE_ORDER = gql`
  mutation DeleteOrder($orderId: Int!) {
    delete_orders_by_pk(id: $orderId) {
      id
    }
  }
`;

const UPDATE_ORDER_EXPECTED_TIME = gql`
  mutation UpdateOrderExpectedTime($orderId: Int!, $expectedTime: timestamptz!) {
    update_orders_by_pk(pk_columns: {id: $orderId}, _set: {expected_time: $expectedTime}) {
      id
      expected_time
    }
  }
`;



const OrderApp = ({ restaurantId }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [cart, setCart] = useState([]);
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);
  const [selectedDish, setSelectedDish] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [note, setNote] = useState('');
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [showOrderTypeModal, setShowOrderTypeModal] = useState(true);

  const categoriesRef = useRef(null);

  const { loading, error, data } = useQuery(GET_RESTAURANT_DATA, {
    variables: { restaurantId },
  });

  const [createOrder] = useMutation(CREATE_ORDER_MUTATION);
  const [createOrderItems] = useMutation(CREATE_ORDER_ITEMS_MUTATION);
  const [deleteOrderMutation] = useMutation(DELETE_ORDER);
  const [updateOrderExpectedTimeMutation] = useMutation(UPDATE_ORDER_EXPECTED_TIME);


  const handleCheckout = async (paymentMethod, orderDetails) => {
    if (cart.length === 0) {
      alert('購物車為空');
      return;
    }

    const totalAmount = getTotalPrice();

    try {
      // 1. 創建訂單
      let customer_info = {
        name: orderDetails.name,
        phone: orderDetails.phone,
        address: orderType === 'delivery' ? orderDetails.address : null
      };
      console.log('準備創建訂單，訂單詳情:', { orderDetails, customer_info, paymentMethod });

      const { data: orderData } = await createOrder({
        variables: {
          input: {
            table_id: orderType === 'dineIn' ? selectedTable : null,
            total_amount: totalAmount,
            status: 'submitted',
            pay_method: paymentMethod,
            priority: orderDetails.priority,
            order_type: orderType,
            expected_time: orderDetails.pickupTime ? new Date(orderDetails.pickupTime).toISOString() : null,
            customer_info: JSON.stringify(customer_info)
          }
        }
      });

      console.log('訂單創建成功，訂單數據:', orderData);

      if (orderData && orderData.insert_orders_one) {
        const orderId = orderData.insert_orders_one.id;

        /*
        // 2. 更新 Heroku Queue
        const updateQueueResult = await updateHerokuQueue(orderId, {
          ...orderDetails,
          paymentMethod,
          customer_info
        });

        if (updateQueueResult.status === 'proposed') {
          // 時間有衝突，詢問用戶是否接受新的時間
          const newEndTime = moment(updateQueueResult.endTime);
          const newStartTime = moment(newEndTime).subtract(orderDetails.duration, 'minutes');
          
          const userAcceptsNewTime = window.confirm(
            `由於時間衝突，系統建議的新送達時間為 ${newEndTime.format('YYYY-MM-DD HH:mm')}，` +
            `配送將從 ${newStartTime.format('HH:mm')} 開始。\n\n` +
            `是否接受這個新的送達時間？`
          );
          
          if (!userAcceptsNewTime) {
            // 如果用戶不接受新時間，刪除剛才創建的訂單
            await deleteOrder(orderId);
            alert('訂單已取消。');
            return;
          }

          // 更新訂單詳情中的送達時間
          orderDetails.deliveryTime = newEndTime.toDate();
          
          // 更新訂單的 expected_time
          await updateOrderExpectedTime(orderId, orderDetails.deliveryTime);

          // 重新嘗試更新 Heroku Queue，這次應該不會有衝突
          const retryUpdateQueueResult = await updateHerokuQueue(orderId,  {
            ...orderDetails,
            paymentMethod,
            customer_info
          });
          
          if (retryUpdateQueueResult.status !== 'accepted') {
            throw new Error('無法將訂單加入隊列，即使在調整時間後');
          }
        }
          */

        // 3. 創建訂單項目
        const orderItems = cart.map(item => ({
          order_id: orderId,
          dish_id: item.id,
          quantity: item.quantity,
          unit_price: item.price,
          subtotal: item.price * item.quantity,
          dish_items_text: JSON.stringify({
            options: item.options,
            note: item.note
          })
        }));

        const { data: orderItemsData } = await createOrderItems({
          variables: {
            objects: orderItems
          }
        });

        console.log('訂單項目創建成功，數據:', orderItemsData);

        if (orderItemsData && orderItemsData.insert_order_items) {
          alert('訂單已成功提交！訂單編號：' + orderId);
          setCart([]);
          setShowCheckoutModal(false);
        }
      }
    } catch (error) {
      console.error('提交訂單時發生錯誤：', error);
      alert('提交訂單時發生錯誤，請稍後再試。錯誤詳情：' + error.message);
    }
  };

  const updateHerokuQueue = async (orderId, orderDetails) => {
    const deliverymanId = 'deliveryman1'; // 這裡可能需要動態獲取或從props傳入
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/schedule`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          deliverymanId,
          order: {
            id: orderId,
            endTime: orderDetails.deliveryTime,
            duration: orderDetails.duration || 15, // 假設默認配送時間為15分鐘
            priority: orderDetails.priority,
            address: orderDetails.customer_info.address,
            name: orderDetails.customer_info.name,
            phone: orderDetails.customer_info.phone,
            paymentMethod: orderDetails.paymentMethod,
            order_type: orderType
          },
        }),
      });

      if (!response.ok) {
        throw new Error('更新 Heroku Queue 失敗');
      }

      const result = await response.json();
      console.log('Heroku Queue 更新結果:', result);
      return result;
    } catch (error) {
      console.error('更新 Heroku Queue 時發生錯誤:', error);
      throw error;
    }
  };

  const deleteOrder = async (orderId) => {
    try {
      const { data } = await deleteOrderMutation({
        variables: { orderId },
      });
      if (data && data.delete_orders_by_pk) {
        console.log(`訂單 ${orderId} 已成功刪除`);
      } else {
        throw new Error('刪除訂單失敗');
      }
    } catch (error) {
      console.error('刪除訂單時發生錯誤：', error);
      throw error;
    }
  };

  const updateOrderExpectedTime = async (orderId, newExpectedTime) => {
    try {
      const { data } = await updateOrderExpectedTimeMutation({
        variables: { 
          orderId, 
          expectedTime: newExpectedTime.toISOString() 
        },
      });
      if (data && data.update_orders_by_pk) {
        console.log(`訂單 ${orderId} 的預期時間已更新為 ${newExpectedTime}`);
      } else {
        throw new Error('更新訂單預期時間失敗');
      }
    } catch (error) {
      console.error('更新訂單預期時間時發生錯誤：', error);
      throw error;
    }
  };

  useEffect(() => {
    if (data && data.categories.length > 0) {
      setSelectedCategory(data.categories[0].id);
    }
  }, [data]);

  useEffect(() => {
    liff.init({ liffId: '2006415476-yM6j1n0k' }).then(() => {
      // LIFF 初始化成功
    }).catch((err) => {
      console.error('LIFF initialization failed', err);
    });
  }, []);

  if (loading) return <Loader2 className="animate-spin" />;
  if (error) return <p className="text-red-500">錯誤: {error.message}</p>;

  const restaurant = data.restaurants_by_pk;
  const categories = data.categories;
  const dishes = data.dishes;
  const dishOptions = data.dish_options;
  const dishOptionItems = data.dish_option_items;
  const tables = data.tables;

  const addToCart = (dish, options = {}, note = '') => {
    const cartItem = {
      ...dish,
      options: Object.fromEntries(
        Object.entries(options).map(([optionId, selectedItems]) => [
          optionId,
          Array.isArray(selectedItems) ? selectedItems : [selectedItems]
        ])
      ),
      note: note,
      quantity: 1,
    };
    setCart([...cart, cartItem]);
  };

  const removeFromCart = (index) => {
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
  };

  const getDishesForCategory = (categoryId) => {
    return dishes.filter(dish => dish.category_id === categoryId);
  };

  const getDishOptions = (dishId) => {
    return dishOptions.filter(option => option.dish_id === dishId);
  };

  const getDishOptionItems = (optionId) => {
    return dishOptionItems.filter(item => item.dish_option_id === optionId);
  };

  const scrollCategories = (direction) => {
    if (categoriesRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      categoriesRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const openOptionModal = (dish) => {
    setSelectedDish(dish);
    setSelectedOptions({});
    setNote('');
    setShowOptionModal(true);
  };

  const closeOptionModal = () => {
    setShowOptionModal(false);
    setSelectedDish(null);
    setSelectedOptions({});
    setNote('');
  };

  const handleOptionChange = (optionId, itemId) => {
    setSelectedOptions(prev => {
      const option = dishOptions.find(o => o.id === optionId);
      if (option.type === 'checkbox') {
        const prevSelected = prev[optionId] || [];
        if (prevSelected.includes(itemId)) {
          return { ...prev, [optionId]: prevSelected.filter(id => id !== itemId) };
        } else {
          return { ...prev, [optionId]: [...prevSelected, itemId] };
        }
      } else {
        return { ...prev, [optionId]: itemId };
      }
    });
  };

  const addToCartWithOptions = () => {
    addToCart(selectedDish, selectedOptions, note);
    closeOptionModal();
  };

  const getTotalPrice = () => {
    return cart.reduce((total, item) => {
      let itemTotal = item.price * item.quantity;
      Object.entries(item.options).forEach(([optionId, selectedItems]) => {
        if (Array.isArray(selectedItems)) {
          selectedItems.forEach(itemId => {
            const optionItem = dishOptionItems.find(oi => oi.id === itemId);
            if (optionItem) {
              itemTotal += optionItem.price;
            }
          });
        } else {
          const optionItem = dishOptionItems.find(oi => oi.id === selectedItems);
          if (optionItem) {
            itemTotal += optionItem.price;
          }
        }
      });
      return total + itemTotal;
    }, 0);
  };

  const selectTable = (tableId) => {
    setSelectedTable(tableId);
  };

  const selectOrderType = (type) => {
    setOrderType(type);
    setShowOrderTypeModal(false);
  };

  const styles = {
    app: "font-['Noto_Sans_TC',_sans-serif]",
    header: "bg-[#7cd141] text-white p-4 flex justify-between items-center",
    logo: "h-16 w-16",
    categoryButton: "px-4 py-2 mr-2 rounded-full bg-white text-[#2E7D32] border-2 border-[#4CAF50] transition-all duration-300 hover:bg-[#E8F5E9] font-medium",
    categoryButtonActive: "!bg-green-600 text-white border-[#2E7D32] font-bold shadow-md",
    dishCard: "flex items-center mb-4 bg-white p-4 rounded-lg shadow-md transition-shadow duration-300 hover:shadow-lg",
    dishImage: "w-24 h-24 object-cover rounded-lg mr-4",
    dishName: "font-bold text-lg text-[#4a4a4a]",
    dishDescription: "text-sm text-gray-600",
    dishPrice: "text-[#7cd141] font-bold",
    addButton: "bg-[#7cd141] text-white px-4 py-2 rounded-full hover:bg-[#6ab836] transition-colors duration-300",
    checkoutButton: "bg-[#7cd141] text-white px-6 py-3 rounded-full text-lg font-bold hover:bg-[#6ab836] transition-colors duration-300",
    modal: "fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50",
    modalContent: "bg-white rounded-lg p-6 w-full max-w-md max-h-[80vh] flex flex-col",
    modalHeader: "flex justify-between items-center mb-4",
    modalTitle: "text-xl font-bold text-[#4a4a4a]",
    modalCloseButton: "text-gray-500 hover:text-gray-700",
    modalBody: "overflow-y-auto flex-grow",
    modalFooter: "mt-4 pt-4 border-t",
    button: "w-full bg-[#7cd141] text-white px-4 py-2 rounded-full hover:bg-[#6ab836] transition-colors duration-300",
  };

  return (
    <div className={`flex flex-col h-screen bg-gray-100 ${styles.app}`}>
      <header className={styles.header}>
        <div className="flex items-center">
          <img src="/logo-2.jpeg" alt="Logo" className={styles.logo} />
          <div className="ml-2">
            <h1 className="text-xl font-bold">{restaurant.name}</h1>
            {selectedTable && (
              <span className="text-sm">桌號: {tables.find(t => t.id === selectedTable)?.table_number}</span>
            )}
          </div>
        </div>
        <button onClick={() => setShowCartModal(true)} className="flex items-center">
          <ShoppingCart size={24} className="mr-2" />
          <span>{cart.length}</span>
        </button>
      </header>

      <main className="flex-grow overflow-auto">
        <div className="relative">
          <button onClick={() => scrollCategories('left')} className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1 shadow-md z-10">
            <ChevronLeft size={24} />
          </button>
          <div ref={categoriesRef} className="flex overflow-x-auto p-2 bg-gray-100 scrollbar-hide">
            {categories.map(category => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category.id)}
                className={`${styles.categoryButton} ${
                  selectedCategory === category.id ? styles.categoryButtonActive : ''
                }`}
              >
                {category.name}
              </button>
            ))}
          </div>
          <button onClick={() => scrollCategories('right')} className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1 shadow-md z-10">
            <ChevronRight size={24} />
          </button>
        </div>

        <div className="p-4">
          {selectedCategory && getDishesForCategory(selectedCategory).map(dish => (
            <div key={dish.id} className={styles.dishCard}>
              <img 
                src={PCLOUD_IMAGE_PATH+dish.image_url || "/api/placeholder/80/80"} 
                alt={dish.name} 
                className={styles.dishImage}
              />
              <div className="flex-grow">
                <h3 className={styles.dishName}>{dish.name}</h3>
                <p className={styles.dishDescription}>{dish.description}</p>
                <p className={styles.dishPrice}>${dish.price}</p>
                {getDishOptions(dish.id).length > 0 && (
                  <button 
                    onClick={() => openOptionModal(dish)}
                    className="text-sm text-green-500 underline"
                  >
                    自定義選項
                  </button>
                )}
              </div>
              <button
                onClick={() => openOptionModal(dish)}
                className={styles.addButton}
              >
                加入
              </button>
            </div>
          ))}
        </div>
      </main>

      <button 
        className={styles.checkoutButton}
        onClick={() => {
          if (orderType === 'dineIn' && !selectedTable) {
            alert('請先選擇桌號');
          } else {
            setShowCheckoutModal(true);
          }
        }}
      >
        結帳 (${getTotalPrice()})
      </button>

      {showOptionModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <h2 className={styles.modalTitle}>{selectedDish.name} - 自定義選項</h2>
              <button onClick={closeOptionModal} className={styles.modalCloseButton}>
                <X size={24} />
              </button>
            </div>
            <div className={styles.modalBody}>
              {getDishOptions(selectedDish.id).map(option => (
                <div key={option.id} className="mb-4">
                  <h3 className="font-bold mb-2">{option.name}</h3>
                  {getDishOptionItems(option.id).map(item => (
                    <label key={item.id} className="flex items-center mb-2">
                      <input
                        type={option.type === 'radio' ? 'radio' : 'checkbox'}
                        name={`option-${option.id}`}
                        value={item.id}
                        checked={
                          option.type === 'checkbox'
                            ? (selectedOptions[option.id] || []).includes(item.id)
                            : selectedOptions[option.id] === item.id
                        }
                        onChange={() => handleOptionChange(option.id, item.id)}
                        className="mr-2"
                      />
                      <span>{item.name} (+${item.price})</span>
                    </label>
                  ))}
                </div>
              ))}
              <div className="mb-4">
                <textarea
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  className="w-full p-2 border rounded"
                  placeholder="請輸入特殊要求或備註"
                  rows="3"
                />
              </div>
            </div>
            <button
              onClick={addToCartWithOptions}
              className={styles.button}
            >
              加入購物車
            </button>
          </div>
        </div>
      )}

      {showCartModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <h2 className={styles.modalTitle}>購物車內容</h2>
              <button onClick={() => setShowCartModal(false)} className={styles.modalCloseButton}>
                <X size={24} />
              </button>
            </div>
            <div className={styles.modalBody}>
              {cart.map((item, index) => (
                <div key={index} className="flex items-center justify-between mb-4 border-b pb-2">
                  <div>
                    <h3 className="font-bold">{item.name}</h3>
                    <p className="text-sm text-gray-600">
                      ${item.price} x {item.quantity}
                    </p>
                    {Object.entries(item.options).map(([optionId, selectedItems]) => {
                      const option = dishOptions.find(o => o.id === parseInt(optionId));
                      if (Array.isArray(selectedItems)) {
                        return selectedItems.map(itemId => {
                          const optionItem = dishOptionItems.find(oi => oi.id === itemId);
                          return (
                            <p key={itemId} className="text-sm text-gray-500">
                              {option?.name}: {optionItem?.name} (+${optionItem?.price})
                            </p>
                          );
                        });
                      } else {
                        const optionItem = dishOptionItems.find(oi => oi.id === selectedItems);
                        return (
                          <p key={optionId} className="text-sm text-gray-500">
                            {option?.name}: {optionItem?.name} (+${optionItem?.price})
                          </p>
                        );
                      }
                    })}
                    {item.note && (
                      <p className="text-sm text-gray-500">備註: {item.note}</p>
                    )}
                  </div>
                  <button
                    onClick={() => removeFromCart(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    移除
                  </button>
                </div>
              ))}
            </div>
            <div className={styles.modalFooter}>
              <p className="font-bold text-lg">總計: ${getTotalPrice()}</p>
            </div>
            <button
              onClick={() => setShowCartModal(false)}
              className={styles.button}
            >
              關閉
            </button>
          </div>
        </div>
      )}

      {showOrderTypeModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h2 className={styles.modalTitle}>選擇訂單類型</h2>
            <div className="space-y-4 mt-4">
              <button onClick={() => selectOrderType('dineIn')} className={styles.button}>內用</button>
              <button onClick={() => selectOrderType('takeout')} className={styles.button}>外帶自取</button>
              <button onClick={() => selectOrderType('delivery')} className={styles.button}>外送</button>
            </div>
          </div>
        </div>
      )}

      {orderType === 'dineIn' && !selectedTable && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h2 className={styles.modalTitle}>請選擇桌號</h2>
            <div className="grid grid-cols-3 gap-4 mt-4">
              {tables.map(table => (
                <button
                  key={table.id}
                  onClick={() => selectTable(table.id)}
                  className={`p-4 rounded-lg ${
                    table.status === 'available' ? 'bg-green-500 text-white hover:bg-green-600' : 'bg-gray-300 text-gray-600'
                  }`}
                  disabled={table.status !== 'available'}
                >
                  {table.table_number}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      <CheckoutModal 
        isOpen={showCheckoutModal} 
        onClose={() => setShowCheckoutModal(false)} 
        cart={cart} 
        totalPrice={getTotalPrice()} 
        tableNumber={tables.find(t => t.id === selectedTable)?.table_number}
        dishOptions={dishOptions}
        dishOptionItems={dishOptionItems}
        onCheckout={handleCheckout}
        orderType={orderType}
      />

    </div>
  );
};

export default OrderApp;          